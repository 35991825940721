import React from "react"
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import thunk from 'redux-thunk';
import debounce from 'lodash.debounce';
import { composeWithDevTools } from 'redux-devtools-extension';

import state, { initialState, navigate, RESET_STATE } from "./src/ducks/AppState"
import {serializeState} from './src/utils/serializeState';

//const window = require("global/window");

const enhancedCompose = composeWithDevTools({});

const saveToLocal = debounce((data) => {
  try {
    const normalState = serializeState(data);
    localStorage.setItem('colorRamp', JSON.stringify(normalState))
  } catch (err) {}
}, 500);

const persist = store => next => action => {
  const result = next(action);
  if (action.type !== RESET_STATE) {
    next(navigate('/'));
  }
  const state = store.getState();
  saveToLocal(state);
  return result;
}

export default ({ element }) => {
  const store = createStore(state, initialState, enhancedCompose(applyMiddleware(persist, thunk)))
  return <Provider store={store}>{element}</Provider>
}
