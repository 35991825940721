import chroma from 'chroma-js';
import btoa from 'btoa';
import atob from 'atob';

export const serializeState = (state) => {
  const baseColor = state.baseColor.hex();
  return {
    payload: {
      ...state,
      baseColor
    },
    version: '1.0.0'
  };
}

const parsers = {
  '1.0.0': (stateObject) => {
    const colorData = stateObject.payload;
    colorData.baseColor = chroma(colorData.baseColor);
    return colorData;
  }
}

const compressors = {
  '1.0.0': (s) => {
    return [
      '1.0.0',
      s.baseColor,
      s.stops,
      s.hueShift.start,
      s.hueShift.end,
      s.hueMode,
      s.luminance.easing
    ];
  }
}

const decompressors = {
  '1.0.0': (s) => {
    return {
      version: '1.0.0',
      payload: {
        baseColor: s[1],
        stops: s[2],
        hueShift: {
          start: s[3],
          end: s[4]
        },
        hueMode: s[5],
        luminance: {
          easing: s[6]
        }
      }
    };
  }
}

export const restoreState = (stateData) => {
  const stateObject = JSON.parse(stateData);
  if (stateObject.version && parsers[stateObject.version]) {
    return parsers[stateObject.version](stateObject);
  }
  return undefined;
}

export const createStateHash = (state) => {
  const normalState = serializeState(state);
  const compressState = compressors[normalState.version];
  return btoa(JSON.stringify(compressState(normalState.payload)));
}

export const restoreStateHash = (hash) => {
  const d = JSON.parse(atob(hash));
  const version = d[0];
  return parsers[version](decompressors[version](d));
}
