import { produce } from "immer"
import {createBrowserHistory, createMemoryHistory} from 'history';
import chroma from "chroma-js"


const window = require("global/window");

let history;

if (window.history)
  history = createBrowserHistory();
else {
  history = createMemoryHistory();
}

export const SET_BASE_COLOR = "SET_BASE_COLOR"
export const SET_STOP_COUNT = "SET_STOP_COUNT"
export const CLAMP_STOP_COUNT = "CLAMP_STOP_COUNT";
export const SET_HUE_SHIFT = "SET_HUE_SHIFT"
export const SET_HUE_MODE = "SET_HUE_MODE"
export const SET_LUM_EASING = "SET_LUM_EASING"
export const RESET_STATE = "RESET_STATE";

export const setBaseColor = color => ({
  type: SET_BASE_COLOR,
  payload: chroma(color),
})

export const setStopCount = stops => ({
  type: SET_STOP_COUNT,
  payload: stops
});

export const clampStopCount = stops => ({
  type: CLAMP_STOP_COUNT,
  payload: Math.min(Math.max(stops, 1), 100)
});

export const setHueShift = (position, shift) => ({
  type: SET_HUE_SHIFT,
  payload: {
    position,
    shift,
  },
})

export const setHueMode = mode => ({
  type: SET_HUE_MODE,
  payload: {
    mode,
  },
})

export const setLuminanceEasing = name => ({
  type: SET_LUM_EASING,
  payload: name,
})

export const resetState = (state) => {
  if (!state) {
    history.push('/');
  }
  return {
    type: RESET_STATE,
    payload: state || initialState
  }
};

export const navigate = path => dispatch => history.push(path);

export const initialState = {
  baseColor: chroma("00A0F5"),
  stops: 11,
  hueShift: {
    start: 0,
    end: 0,
  },
  hueMode: "hsluv",
  luminance: {
    easing: "linear",
  },
}

const reducer = (state, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case SET_BASE_COLOR:
        draft.baseColor = action.payload;
        break
      case SET_STOP_COUNT:
        draft.stops = action.payload;
        break
      case CLAMP_STOP_COUNT:
        draft.stops = action.payload
        break
      case SET_HUE_SHIFT:
        draft.hueShift[action.payload.position] = action.payload.shift
        break
      case SET_HUE_MODE:
        draft.hueMode = action.payload.mode
        break
      case SET_LUM_EASING:
        draft.luminance.easing = action.payload
        break
      case RESET_STATE:
        return action.payload;
      default:
        break
    }
  })
}

export default reducer
